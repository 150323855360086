import React, { useState, useContext } from "react";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import TagEditor from "../../../../SavedTags/components/TagEditor/TagEditor";
import CompactTagsTable from "../../../../SavedTags/components/CompactTagsTable";
import { AutoSuggestedMetadata } from "./Panels/AutoSuggestedMetadata";
import { FilePickerModal } from "../../../../../utils/FilePickerModal";

export const AddNodeSidePanel = ({ handleAddNodeApplied }) => {
  const {
    setSidePanelOpen,
    selectedNodeData,
    setSelectedNodeData,
    refreshHierarchyStats,
    handleSaveGraph,
  } = useContext(GraphContext);
  const { deasyUserConfig } = useContext(BaseContext);

  const [tagInEditor, setTagInEditor] = useState({});
  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);
  const [activeTagEditorSection, setActiveTagEditorSection] =
    useState("general");
  const handleApply = async () => {
    if (!tagInEditor || !selectedNodeData) return;

    try {
      await handleAddNodeApplied(selectedNodeData, tagInEditor);

      await refreshHierarchyStats(deasyUserConfig.deasyApiKey);

      await handleSaveGraph();
    } catch (error) {
      console.error("Error applying tag:", error);
    }
  };

  const [openFilePicker, setOpenFilePicker] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const renderModeSelector = () => (
    <div className="p-6">
      <h2 className="text-lg font-semibold mb-4">
        How would you like to add metadata?
      </h2>
      <div className="space-y-3">
        <button
          onClick={() => setSelectedMode("manual")}
          className="w-full text-left px-4 py-3 hover:bg-gray-50 rounded-md border border-gray-200"
        >
          <div className="font-medium">Manual Definition</div>
          <div className="text-sm text-gray-500">
            Create a new metadata tag from scratch
          </div>
        </button>
        <button
          onClick={() => setSelectedMode("library")}
          className="w-full text-left px-4 py-3 hover:bg-gray-50 rounded-md border border-gray-200"
        >
          <div className="font-medium">Metadata Library</div>
          <div className="text-sm text-gray-500">
            Select existing metadata tag
          </div>
        </button>
        <button
          onClick={() => setSelectedMode("auto")}
          className="w-full text-left px-4 py-3 hover:bg-gray-50 rounded-md border border-gray-200"
        >
          <div className="font-medium">Suggest Metadata</div>
          <div className="text-sm text-gray-500">
            Deasy-powered metadata suggestions
          </div>
        </button>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (selectedMode) {
      case "manual":
        return (
          <TagEditor
            showHeader={false}
            includeNewTagButton={true}
            tagInEditor={tagInEditor}
            setTagInEditor={setTagInEditor}
            isTagEditorCollapsed={isTagEditorCollapsed}
            setIsTagEditorCollapsed={setIsTagEditorCollapsed}
            activeTagEditorSection={activeTagEditorSection}
            setActiveTagEditorSection={setActiveTagEditorSection}
          />
        );
      case "library":
        return (
          <CompactTagsTable
            hideNewTagButton={true}
            tagInEditor={tagInEditor}
            setTagInEditor={setTagInEditor}
          />
        );
      case "auto":
        return (
          <AutoSuggestedMetadata
            setOpenFilePicker={setOpenFilePicker}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="absolute right-0 top-0 h-screen bg-white border border-gray-200 flex flex-col rounded-l-md z-50 animate-slideIn"
      style={{ width: "500px" }}
    >
      {/* Close button */}
      <button
        onClick={() => {
          setSelectedNodeData({});
          setSidePanelOpen(false);
          setSelectedMode(null);
        }}
        className="absolute top-1 right-1 z-10 p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
      >
        ✕
      </button>

      {/* Main content container */}
      <div className="flex flex-col h-full overflow-hidden">
        {!selectedMode ? (
          renderModeSelector()
        ) : (
          <>
            {/* Header */}
            <div className="flex-shrink-0 p-4 border-b">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setSelectedMode(null)}
                  className="p-1 hover:bg-gray-100 rounded-full"
                >
                  ←
                </button>
                <h2 className="text-lg font-semibold">
                  {selectedMode === "manual" && "Define New Tag"}
                  {selectedMode === "library" && "Select from Metadata Library"}
                  {selectedMode === "auto" && "Suggest Metadata"}
                </h2>
              </div>
            </div>

            {/* Content area */}
            <div className="flex-1 flex flex-col min-h-0">
              <div className="flex-1 overflow-y-auto border border-gray-200">
                {renderContent()}
              </div>
              {selectedMode !== "auto" && (
                <div className="flex-shrink-0 p-4 border-t bg-white">
                  <button
                    className={`w-full btn ${!tagInEditor || Object.keys(tagInEditor).length === 0 ? "btn-disabled" : "btn-primary"}`}
                    onClick={handleApply}
                    disabled={
                      !tagInEditor || Object.keys(tagInEditor).length === 0
                    }
                  >
                    {!tagInEditor || Object.keys(tagInEditor).length === 0
                      ? "Select a tag to apply"
                      : "Include Tag"}
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <FilePickerModal
        openFilePicker={openFilePicker}
        setOpenFilePicker={setOpenFilePicker}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
    </div>
  );
};
