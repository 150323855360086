import React from "react";
import {
  FaProjectDiagram,
  FaBook,
  FaPlug,
  FaKey,
  FaTags,
  FaChartLine,
  FaSignOutAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";

const MainNavigation = ({ onItemSelect, selectedItem }) => {
  const navigate = useNavigate();
  const navItems = [
    {
      id: "workflows",
      label: "Deasy workflows",
      icon: FaProjectDiagram,
      description:
        "Create and manage your data workflows. Configure LLM endpoints and process your data.",
    },
    {
      id: "metadata",
      label: "Metadata library",
      icon: FaBook,
      description:
        "Browse and manage your metadata schemas. Create and edit tags for data classification.",
    },
    {
      id: "metadata-studio",
      label: "Test metadata",
      icon: FaTags,
      description:
        "Test and validate your metadata tags on sample data. Fine-tune your classification.",
    },
    {
      id: "connections",
      label: "Data connections",
      icon: FaPlug,
      description:
        "Configure your vector database and data source connections.",
    },
    {
      id: "tokens",
      label: "API Keys & Tier",
      icon: FaKey,
      description:
        "Manage your API keys and subscription tier for accessing Deasy services.",
    },
    {
      id: "progress-tracking",
      label: "Progress tracking",
      icon: FaChartLine,
      description: "Monitor your workflow progress and metadata health scores.",
    },
  ];

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const getItemClass = (itemId) => {
    const isSelected = selectedItem === itemId;
    return `flex cursor-pointer text-left p-1 gap-2 transition-colors w-full justify-between items-center duration-200 whitespace-nowrap ${
      isSelected ? "text-emerald-400" : "text-gray-400 hover:text-emerald-300"
    }`;
  };

  return (
    <nav className="h-screen w-30 bg-[#1a1f2b] flex flex-col items-center py-4 px-4">
      <div className="mb-8">
        <img src="/deasy-icon.png" alt="Deasy Labs" className="w-8 h-8" />
      </div>

      <div className="flex flex-col space-y-6 flex-grow">
        {navItems.map((item) => {
          const Icon = item.icon;
          return (
            <div
              key={item.id}
              className={getItemClass(item.id)}
              onClick={() => onItemSelect(item.id)}
              title={item.description}
            >
              <div className="flex items-center gap-2">
                <Icon size={15} />
                <p>{item.label}</p>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className="flex cursor-pointer text-left p-1 gap-2 transition-colors w-full justify-between items-center duration-200 whitespace-nowrap text-gray-400 hover:text-emerald-300 mt-auto"
        onClick={handleLogout}
        title="Sign out"
      >
        <div className="flex items-center gap-2">
          <FaSignOutAlt size={15} />
          <p>Sign Out</p>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;
