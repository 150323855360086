import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import { nodeTypes } from "./Graph/GraphNodes";
import { GraphContext } from "../../../../../contexts/GraphContext";
import { useContext, useEffect, useState } from "react";
import { AddNodeSidePanel } from "./Graph/AddNodeSidePanel";
import { metadataService } from "../../../../../services/api";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { auth } from "../../../../../config/firebase";
import { FiRefreshCw } from "react-icons/fi";
import { FiSave } from "react-icons/fi";
import { prepareVectorDBConfiguration } from "../../../../../services/utils";
import { WorkflowType } from "../../../../../models/WorkflowModel";
import GraphSelector from "./Graph/GraphSelector";
import { v4 as uuidv4 } from "uuid";
import { prepareEndpointManagerConfig } from "../../../../../services/utils";
import { Zap } from "lucide-react";
import { toast } from "react-hot-toast";
import { FilePickerModal } from "../../../../utils/FilePickerModal";
import { processData } from "./GraphUtils";
import { DatabaseZap, BetweenVerticalStart } from "lucide-react";
import dagre from "dagre";
import { RotateCcw } from "lucide-react";
import {
  FiChevronRight,
  FiChevronLeft,
  FiChevronDown,
  FiPlus,
  FiMinus,
} from "react-icons/fi";
import CreateDataSlice from "../../../../utils/CreateDataSlice";
import { CircularProgress } from "@mui/material";

function getLayoutedElements(nodes, edges, direction = "TB") {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction, ranksep: 80, nodeSep: 30 });

  // Assign each node in dagre so it can calculate positions
  nodes.forEach((node) => {
    // You might adjust width/height as appropriate
    dagreGraph.setNode(node.id, { width: 180, height: 60 });
  });

  // Assign edges
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  // Update node positions based on dagre's calculations
  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - (node.width || 90) / 2,
        y: nodeWithPosition.y - (node.height || 30) / 2,
      },
      positionAbsolute: {
        x: nodeWithPosition.x - (node.width || 90) / 2,
        y: nodeWithPosition.y - (node.height || 30) / 2,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
}

const getAllTagNames = (data) => {
  const names = new Set();
  const traverse = (obj) => {
    if (!obj || typeof obj !== "object") return;
    Object.entries(obj).forEach(([key, value]) => {
      if (key !== "TagAvailableValues" && value?.TagAvailableValues) {
        names.add(key);
      }
      if (typeof value === "object" && value !== null) {
        traverse(value);
      }
    });
  };
  traverse(data);
  return names;
};

const getAllChildTags = (data) => {
  const tags = new Map();

  const traverse = (obj) => {
    if (!obj || typeof obj !== "object") return;

    Object.entries(obj).forEach(([key, value]) => {
      if (key !== "TagAvailableValues" && value?.TagAvailableValues) {
        tags.set(key, value);
      }
      if (typeof value === "object" && value !== null) {
        traverse(value);
      }
    });
  };

  traverse(data);
  return Array.from(tags.entries()).map(([tagName, tagData]) => ({
    tagName,
    tagData,
  }));
};

const getChildTagsForTag = (data, targetTag) => {
  const childTags = new Set();

  const traverse = (obj) => {
    if (!obj || typeof obj !== "object") return;

    Object.entries(obj).forEach(([key, value]) => {
      if (key === targetTag) {
        // Once we find our target tag, collect all child tags within its subtree
        getAllChildTags(value).forEach(({ tagName }) => {
          childTags.add(tagName);
        });
      } else if (typeof value === "object" && value !== null) {
        traverse(value);
      }
    });
  };

  traverse(data);
  return childTags;
};

const getAllTagsFromGraph = (data) => {
  const tags = new Set();
  const traverse = (obj) => {
    if (!obj || typeof obj !== "object") return;
    Object.entries(obj).forEach(([key, value]) => {
      // Count any tag that has TagAvailableValues, regardless of whether it has child values
      if (key !== "TagAvailableValues" && value?.TagAvailableValues) {
        tags.add(key);
      }
      if (typeof value === "object" && value !== null) {
        traverse(value);
      }
    });
  };
  traverse(data);
  return tags;
};

// First, add a helper function to get tags from selected nodes
const getTagsFromSelectedNodes = (nodes, graphData) => {
  if (!nodes.length) {
    return getAllTagsFromGraph(graphData);
  }

  const tags = new Set();
  nodes.forEach((node) => {
    let currentData = graphData;
    // Navigate to the selected node's data
    for (const pathItem of node.data.nodePath) {
      if (!currentData[pathItem]) break;
      currentData = currentData[pathItem];
    }
    // Get tags from this node's subtree
    const nodeTags = getAllTagsFromGraph(currentData);
    nodeTags.forEach((tag) => tags.add(tag));
  });
  return tags;
};

const ExtractMetadataTab = () => {
  const {
    deasyUserConfig,
    savedTags,
    setCurrentScreen,
    setSelectedUseCase,
    vdbFilesCount,
    isConfigRefreshing,
  } = useContext(BaseContext);
  const {
    setGraphs,
    selectedNodeData,
    setSelectedNodeData,
    setSidePanelOpen,
    hierarchyStats,
    refreshHierarchyStats,
    discoveryGraphData,
    setDiscoveryGraphData,
    selectedDiscoveryGraph,
    activeDragNode,
    activeOverlappingNode,
    setActiveDragNode,
    setActiveOverlappingNode,
    selectedNodes,
    setSelectedNodes,
    sidePanelOpen,
    handleSaveGraph,
  } = useContext(GraphContext);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedNodesFileCount, setSelectedNodesFileCount] = useState(0);
  const [isLoadingFileCount, setIsLoadingFileCount] = useState(false);
  const [showCreateDataSlice, setShowCreateDataSlice] = useState(false);

  const [isClassifying, setIsClassifying] = useState(false);
  const [classifyProgress, setClassifyProgress] = useState(0.0);
  const [classifyTagsCreated, setClassifyTagsCreated] = useState(0);
  const [selectedClassifyFiles, setSelectedClassifyFiles] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [filePickerOpen, setFilePickerOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [collapsedTags, setCollapsedTags] = useState(() =>
    getAllTagNames(discoveryGraphData),
  );
  const [tagSearchTerm, setTagSearchTerm] = useState("");
  const [showTagPanel, setShowTagPanel] = useState(true);
  const [showTagsList, setShowTagsList] = useState(false);

  const [excludedTags, setExcludedTags] = useState(new Set());

  const [showValueNodes, setShowValueNodes] = useState(true);
  const [showTagNodes, setShowTagNodes] = useState(true);

  const {
    vdbmConfig: { Configs: vdbmConfigs, LastActive: vdbmLastActive },
    llmConfig: { Configs: llmConfigs, LastActive: llmLastActive },
    deasyApiKey,
  } = deasyUserConfig;
  const vectorDBConfiguration = vdbmConfigs[vdbmLastActive];
  const llmEndpointConfiguration = llmConfigs[llmLastActive];

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  // Update nodes when hierarchyStats changes
  useEffect(() => {
    const getFilteredNodes = (nodes) => {
      return nodes.map((node) => {
        // Always show root nodes
        if (node.type === "root") return node;

        // Hide value nodes if showValueNodes is false
        if (node.type === "value" && !showValueNodes) {
          return { ...node, hidden: true };
        }

        // Hide tag nodes if showTagNodes is false
        if (node.type === "question" && !showTagNodes) {
          return { ...node, hidden: true };
        }

        return { ...node, hidden: false };
      });
    };

    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      discoveryGraphData,
      hierarchyStats?.file_count || 0,
    );

    // 1. Layout the nodes/edges with dagre
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      updatedNodes,
      updatedEdges,
      "TB",
    );

    // 2. Apply visibility filters
    const filteredNodes = getFilteredNodes(layoutedNodes);

    setNodes(filteredNodes);
    setEdges(layoutedEdges);
  }, [
    hierarchyStats,
    discoveryGraphData,
    setNodes,
    setEdges,
    showValueNodes,
    showTagNodes,
  ]);

  // For highlighting matching nodes based on label
  useEffect(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        // Only consider it a match if there's a search term and the label includes it
        const labelMatches =
          searchTerm.trim() !== "" &&
          node?.data?.label?.toLowerCase()?.includes(searchTerm.toLowerCase());

        return {
          ...node,
          className: labelMatches
            ? " shadow-2xl scale-125 z-50 border-2 border-primary rounded-lg animate-pulse"
            : "",
        };
      }),
    );
  }, [searchTerm, setNodes]);

  const handleNodeAddClicked = (nodeData, nodeType) => {
    setSelectedNodeData({ ...nodeData, nodeType });
    setSidePanelOpen(true);
  };

  const removeNodeFromStructure = (data, nodePath) => {
    let current = data;

    // Handle empty path or root node
    if (!nodePath || nodePath.length === 0) {
      return;
    }

    // Navigate to parent node
    for (let i = 0; i < nodePath.length - 1; i++) {
      const pathItem = nodePath[i];
      if (!(pathItem in current)) {
        return; // Node path is invalid
      }
      current = current[pathItem];
    }

    const targetNodeName = nodePath[nodePath.length - 1];

    // Delete the node
    if (current) {
      delete current[targetNodeName];

      // Only filter TagAvailableValues if it exists
      if (
        current.TagAvailableValues &&
        Array.isArray(current.TagAvailableValues)
      ) {
        current.TagAvailableValues = current.TagAvailableValues.filter(
          (value) => value !== targetNodeName,
        );
      }

      // Clean up empty objects
      if (
        Object.keys(current).length === 0 ||
        (Object.keys(current).length === 1 &&
          current.TagAvailableValues?.length === 0)
      ) {
        delete current.TagAvailableValues;
      }
    }
  };

  const handleNodeDeletion = (nId) => {
    // Get all descendant nodes recursively
    const nodesToDelete = new Set();

    const collectDescendants = (currentId) => {
      nodesToDelete.add(currentId);
      const childEdges = edges.filter((edge) => edge.source === currentId);
      childEdges.forEach((edge) => collectDescendants(edge.target));
    };

    collectDescendants(nId);

    // Update the discoveryGraphData by removing nodes
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));

    nodesToDelete.forEach((nodeId) => {
      const node = nodes.find((n) => n.id === nodeId);
      if (node) {
        removeNodeFromStructure(updatedData, node.data.nodePath);
      }
    });

    // Re-process the graph
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      1000,
    );

    setDiscoveryGraphData(updatedData);
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  const handleAddNodeApplied = async (selectedNodeData, tag) => {
    if (!selectedNodeData || !tag) return;

    // Get the complete tag info from savedTags
    const completeTag = savedTags.find((t) => t.name === tag.name);

    // Create a deep copy of the data structure
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));

    // Helper function to navigate and update the data structure
    const addNodeToStructure = (data, nodePath, newTag) => {
      let current = data;

      // Use the original available_values from the complete tag
      const tagAvailableValues = completeTag?.available_values || [];

      if (selectedNodeData.label === "Root") {
        current[newTag.name] = {
          TagAvailableValues: tagAvailableValues,
        };

        // Always add <ANY> node if there are no available values
        if (!tagAvailableValues || tagAvailableValues.length === 0) {
          current[newTag.name]["<ANY>"] = {};
        } else {
          // Add nodes for each available value
          tagAvailableValues.forEach((value) => {
            current[newTag.name][value] = {};
          });
        }
      } else if (selectedNodeData.nodeType === "value") {
        // Navigate to the correct position
        for (let i = 0; i < nodePath.length; i++) {
          const pathItem = nodePath[i];
          if (!(pathItem in current)) {
            current[pathItem] = {};
          }
          current = current[pathItem];
        }

        current[newTag.name] = {
          TagAvailableValues: tagAvailableValues,
        };

        // Always add <ANY> node if there are no available values
        if (!tagAvailableValues || tagAvailableValues.length === 0) {
          current[newTag.name]["<ANY>"] = {};
        } else {
          // Add nodes for each available value
          tagAvailableValues.forEach((value) => {
            current[newTag.name][value] = {};
          });
        }
      } else {
        // For question nodes...
        for (let i = 0; i < nodePath.length - 2; i++) {
          const pathItem = nodePath[i];
          if (!(pathItem in current)) {
            current[pathItem] = {};
          }
          current = current[pathItem];
        }

        const targetNodeName = nodePath[nodePath.length - 2];
        const existingNodeData = JSON.parse(
          JSON.stringify(current[targetNodeName] || {}),
        );

        current[targetNodeName][newTag.name] = {
          TagAvailableValues: tagAvailableValues,
        };

        // Always add <ANY> node if there are no available values
        if (!tagAvailableValues || tagAvailableValues.length === 0) {
          current[targetNodeName][newTag.name]["<ANY>"] = existingNodeData;
        } else {
          // Add nodes for each available value
          tagAvailableValues.forEach((value) => {
            current[targetNodeName][newTag.name][value] = existingNodeData;
          });
        }

        if (current[targetNodeName][selectedNodeData.label]) {
          delete current[targetNodeName][selectedNodeData.label];
        }
      }
    };

    // Add the new node to the data structure
    if (Array.isArray(tag)) {
      tag.forEach((t) =>
        addNodeToStructure(updatedData, selectedNodeData.nodePath || [], t),
      );
    } else {
      addNodeToStructure(updatedData, selectedNodeData.nodePath || [], tag);
    }

    // Update the graph
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      1000,
    );

    setDiscoveryGraphData(updatedData);
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);

    // Refresh hierarchy stats after updating the graph
    try {
      await refreshHierarchyStats(deasyUserConfig.deasyApiKey);
    } catch (error) {
      console.error(
        "Error refreshing hierarchy stats after adding node:",
        error,
      );
    }
  };

  const handleAddAvailableValue = async (selectedNodeData, newValue) => {
    // Create a deep copy of the data structure
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));
    let current = updatedData;

    // Navigate to the tag node
    for (let i = 0; i < selectedNodeData.nodePath.length; i++) {
      const pathItem = selectedNodeData.nodePath[i];
      current = current[pathItem];
    }

    // Update TagAvailableValues array
    if (!current.TagAvailableValues) {
      current.TagAvailableValues = [];
    }
    if (!current.TagAvailableValues.includes(newValue)) {
      current.TagAvailableValues.push(newValue);

      // save the new tag
      const completeTag = savedTags.find(
        (t) => t.name === selectedNodeData.label,
      );
      completeTag.available_values = Array.from(
        new Set([
          ...(completeTag.available_values || []),
          ...current.TagAvailableValues,
        ]),
      );
      await metadataService.updateTag(completeTag, deasyApiKey);
    }

    // Add the new value node if it doesn't exist
    if (!current[newValue]) {
      current[newValue] = {};
    }

    // Update the graph
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      hierarchyStats?.file_count || 0,
    );

    setDiscoveryGraphData(updatedData);
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  const handleRemoveAvailableValue = async (availableValueToRemove) => {
    // Create a deep copy of the data structure
    const updatedData = JSON.parse(JSON.stringify(discoveryGraphData));
    let current = updatedData;

    // Navigate to the tag node
    const tagPath = availableValueToRemove.nodePath.slice(0, -1); // Remove the value itself
    for (const pathItem of tagPath) {
      current = current[pathItem];
    }

    // Remove the value from TagAvailableValues array
    if (current.TagAvailableValues) {
      current.TagAvailableValues = current.TagAvailableValues.filter(
        (value) => value !== availableValueToRemove.label,
      );
    }

    // Remove the value node and its subtree
    delete current[availableValueToRemove.label];

    // If TagAvailableValues is now empty, add the "<ANY>" node
    if (
      !current.TagAvailableValues ||
      current.TagAvailableValues.length === 0
    ) {
      current["<ANY>"] = {};
    }

    // Update the tag definition in savedTags
    const tagName = tagPath[0];
    const completeTag = savedTags.find((t) => t.name === tagName);
    if (completeTag) {
      completeTag.available_values = current.TagAvailableValues;
      try {
        await metadataService.updateTag(completeTag, deasyApiKey);
      } catch (error) {
        console.error("Error updating tag:", error);
        toast.error("Failed to update tag definition");
        return;
      }
    }

    // Update the graph
    const { nodes: updatedNodes, edges: updatedEdges } = processData(
      updatedData,
      hierarchyStats?.file_count || 0,
    );

    setDiscoveryGraphData(updatedData);
    setGraphs((prev) =>
      prev.map((graph) =>
        graph.graph_id === selectedDiscoveryGraph.graph_id
          ? { ...graph, graph_data: updatedData }
          : graph,
      ),
    );
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  const nodesWithCallbacks = nodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      onDeleteNode: handleNodeDeletion,
      onAddNode: handleNodeAddClicked,
      handleAddNodeApplied: handleAddNodeApplied,
      onAddAvailableValue: handleAddAvailableValue,
      onRemoveAvailableValue: handleRemoveAvailableValue,
    },
  }));

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
  const excludeKey = (obj, key) => {
    const { [key]: _, ...rest } = obj; // Destructure to exclude the key
    return rest;
  };
  const resetDragNode = () => {
    if (!activeOverlappingNode) return;
    const activeNodePath = nodesWithCallbacks.filter(
      (node) => node.id === activeDragNode,
    )[0]?.data?.nodePath;
    const overlappingNodePath = nodesWithCallbacks.filter(
      (node) => node.id === activeOverlappingNode,
    )[0]?.data?.nodePath;
    let activeNode = discoveryGraphData;
    for (let i = 0; i < activeNodePath.length - 1; i++) {
      activeNode = activeNode[activeNodePath[i]];
    }
    const newActiveNode = JSON.parse(
      JSON.stringify({
        [activeNodePath.at(-1)]: activeNode[activeNodePath.at(-1)],
      }),
    );

    let overlappingNode = discoveryGraphData;
    for (let i = 0; i < overlappingNodePath.length - 1; i++) {
      overlappingNode = overlappingNode[overlappingNodePath[i]];
    }
    if (Object.keys(overlappingNode).includes(overlappingNodePath.at(-1))) {
      overlappingNode[overlappingNodePath.at(-1)] = {
        ...newActiveNode,
        ...overlappingNode[overlappingNodePath.at(-1)],
      };
    } else {
      overlappingNode[overlappingNodePath.at(-1)] = newActiveNode;
    }

    let oldNode = discoveryGraphData;
    for (let i = 0; i < activeNodePath.length - 2; i++) {
      oldNode = oldNode[activeNodePath[i]];
    }
    oldNode[activeNodePath.at(-2)] = excludeKey(
      oldNode[activeNodePath.at(-2)],
      activeNodePath.at(-1),
    );
    setDiscoveryGraphData({ ...discoveryGraphData });
    setActiveDragNode(null);
    setActiveOverlappingNode(null);
  };
  const handleNodeDrag = (_, node) => {
    setActiveDragNode(node.id);
    setActiveOverlappingNode(checkNodeOverlap(node, nodesWithCallbacks));
  };

  const checkNodeOverlap = (selectedNode, allNodes) => {
    // Skip if no selected node
    if (!selectedNode || selectedNode.type !== "question") {
      return null;
    }

    // Helper function to get node position (handles both position and positionAbsolute)
    const getNodePosition = (node) => {
      return node.positionAbsolute || node.position;
    };

    // Helper function to check if two rectangles overlap
    const doNodesOverlap = (node1, node2) => {
      const pos1 = getNodePosition(node1);
      const pos2 = getNodePosition(node2);

      // Calculate boundaries for both nodes
      const node1Bounds = {
        left: pos1.x,
        right: pos1.x + (node1.width || 0),
        top: pos1.y,
        bottom: pos1.y + (node1.height || 0),
      };

      const node2Bounds = {
        left: pos2.x,
        right: pos2.x + (node2.width || 0),
        top: pos2.y,
        bottom: pos2.y + (node2.height || 0),
      };

      // Check for overlap
      return !(
        node1Bounds.right < node2Bounds.left ||
        node1Bounds.left > node2Bounds.right ||
        node1Bounds.bottom < node2Bounds.top ||
        node1Bounds.top > node2Bounds.bottom
      );
    };

    // Find first overlapping node
    for (const node of allNodes) {
      // Skip checking against itself
      if (node.id === selectedNode.id) {
        continue;
      }

      if (doNodesOverlap(selectedNode, node)) {
        if (node.data.nodePath.includes(selectedNode.data.label)) return null;
        if (node.type === "value") return node.id;
        return null;
      }
    }

    return null;
  };

  const onDrop = (event) => {
    event.preventDefault();

    let tag;
    try {
      const draggedItem = event.dataTransfer.getData("draggedItem");
      tag = draggedItem ? JSON.parse(draggedItem) : null;
    } catch (e) {
      console.error("Error parsing dragged item:", e);
      return;
    }

    if (!tag) return;

    // Set the selected node data for the root if no node is selected
    if (!selectedNodeData) {
      setSelectedNodeData({
        label: "Root",
        nodeType: "root",
        nodePath: [],
      });
    }

    setTimeout(() => {
      handleAddNodeApplied(selectedNodeData, tag);
    }, 0);
  };

  const getConditionsFromNodes = (nodes) => {
    return nodes.map((node) => {
      const nodePath = node.data.nodePath;
      let condition = {};
      for (let i = 0; i < nodePath.length; i += 2) {
        const tag = nodePath[i];
        const value = nodePath[i + 1];
        if (tag && value) {
          condition[tag] = [value];
        }
      }
      return condition;
    });
  };

  useEffect(() => {
    const fetchFileCount = async () => {
      if (!selectedNodes.length) {
        setSelectedNodesFileCount(0);
        return;
      }

      setIsLoadingFileCount(true);
      try {
        const conditions = getConditionsFromNodes(selectedNodes);
        const response = await metadataService.getUsecaseFileCount(
          prepareVectorDBConfiguration({
            ...vectorDBConfiguration,
            user: auth.currentUser.email,
          }),
          conditions,
          null,
          deasyApiKey,
        );

        if (response.data?.file_count !== undefined) {
          setSelectedNodesFileCount(response.data.file_count);
        }
      } catch (error) {
        console.error("Error fetching file count:", error);
        setSelectedNodesFileCount(0);
      } finally {
        setIsLoadingFileCount(false);
      }
    };

    fetchFileCount();
  }, [selectedNodes, deasyApiKey, vectorDBConfiguration]);

  const isAllFilesSelected = (files) => files === null;

  const classify = async () => {
    // First define the getAllNodes helper function
    const getAllNodes = (obj, nodes = new Set()) => {
      if (!obj || typeof obj !== "object") {
        return nodes;
      }

      Object.entries(obj).forEach(([key, value]) => {
        if (key === "TagAvailableValues") {
          return;
        }

        if (value && typeof value === "object") {
          if ("TagAvailableValues" in value) {
            nodes.add(key);
          }

          if (!Array.isArray(value)) {
            getAllNodes(value, nodes);
          }
        }
      });

      return nodes;
    };

    // Then get all nodes before we need them
    const allNodes = getAllNodes(discoveryGraphData);

    const isRootSelection =
      selectedNodes.length === 0 && !selectedClassifyFiles;
    const numberOfFilesToClassify = isRootSelection
      ? vdbFilesCount?.total_files || 0
      : isAllFilesSelected(selectedClassifyFiles)
        ? vdbFilesCount?.total_files || 0
        : selectedClassifyFiles?.length > 0
          ? selectedClassifyFiles.length
          : selectedNodesFileCount;

    if (numberOfFilesToClassify === 0) return;

    // Now we can use allNodes in the filter
    const selectedTagNames = savedTags
      .filter((tag) => allNodes.has(tag.name) && !excludedTags.has(tag.name))
      .map((tag) => tag.name);

    // Ask the user for confirmation with detailed information
    const confirmation = window.confirm(
      `You are about to extract ${selectedTagNames.length} metadata tags:\n` +
        `${selectedTagNames.join(", ")}\n\n` +
        `This will process ${numberOfFilesToClassify.toLocaleString()} file${
          numberOfFilesToClassify === 1 ? "" : "s"
        }${isRootSelection ? " from the entire dataset" : ""}.\n\n` +
        `This may take some time. Do you want to continue?`,
    );

    if (!confirmation) {
      return;
    }

    const tagsInGraph = savedTags.reduce((filteredObj, tag) => {
      if (selectedTagNames.includes(tag.name)) {
        filteredObj[tag.name] = tag;
      }
      return filteredObj;
    }, {});

    if (selectedTagNames.length === 0) {
      console.log("No tags identified in the graph");
      return;
    }

    setIsClassifying(true);
    setClassifyProgress(0.0);
    setClassifyTagsCreated(0);
    const jobID = uuidv4();

    if (
      isRootSelection ||
      selectedClassifyFiles === null ||
      selectedNodesFileCount > 0
    ) {
      const conditions = isRootSelection
        ? []
        : getConditionsFromNodes(selectedNodes);
      metadataService.classifyAll(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        prepareEndpointManagerConfig(llmEndpointConfiguration),
        tagsInGraph,
        deasyApiKey,
        jobID,
        null,
        numberOfFilesToClassify,
        true,
        discoveryGraphData,
        conditions,
      );
    } else if (selectedClassifyFiles?.length > 0) {
      setJobId(jobID);
      metadataService
        .classify(
          prepareVectorDBConfiguration(vectorDBConfiguration),
          prepareEndpointManagerConfig(llmEndpointConfiguration),
          selectedClassifyFiles,
          tagsInGraph,
          deasyApiKey,
          false,
          false,
          discoveryGraphData,
          jobID,
          true,
          null,
          false,
          false,
        )
        .catch((error) => {
          setIsClassifying(false);
          console.error(error);
          toast.error(error.response.data.detail);
        });
    }
  };

  useEffect(
    () => {
      const trackJobProgress = async () => {
        try {
          if (!jobId || !isClassifying) return;

          const response = await metadataService.trackJob(jobId, deasyApiKey);
          const progress = Number(
            (
              response.data.completed_progress_increments /
              response.data.total_progress_increments
            ).toFixed(4),
          );
          const tagsCreated = response.data?.tags_created || 0;

          // If there's a measurable progress update, update state (and optionally refresh stats)
          if (progress > classifyProgress) {
            setClassifyProgress(progress);
            setClassifyTagsCreated(tagsCreated);
            // Optionally refresh stats upon progress change if needed:
            // refreshHierarchyStats(deasyApiKey);
          }

          // Once the job status is no longer "in_progress" or "failed", classification is done
          if (
            response.data.status !== "in_progress" &&
            response.data.status !== "failed"
          ) {
            setIsClassifying(false);
            setClassifyProgress(0.0);
            setClassifyTagsCreated(0);

            // Only refresh once when classification is actually finished
            refreshHierarchyStats(deasyApiKey);
            toast.success("Classifying files completed successfully");
          }
        } catch (error) {
          // If tracking fails, stop classifying to prevent loops
          setIsClassifying(false);
          setClassifyProgress(0.0);
          setClassifyTagsCreated(0);
          refreshHierarchyStats(deasyApiKey);
          console.error(error);
        }
      };

      // Only set up the poll if we are in the middle of classification
      if (jobId && isClassifying) {
        // Initial call
        trackJobProgress();
        // Then poll every 7 seconds
        const pollInterval = setInterval(trackJobProgress, 7000);
        return () => clearInterval(pollInterval);
      }
      // Otherwise do nothing
    },
    // eslint-disable-next-line
    [jobId, deasyApiKey, classifyProgress, isClassifying, metadataService],
  );

  // First, let's add a useEffect to handle the selected files state
  useEffect(() => {
    if (
      selectedClassifyFiles === null || // "All Files" selected
      (Array.isArray(selectedClassifyFiles) && selectedClassifyFiles.length > 0)
    ) {
      setSelectedNodesFileCount(0); // Reset node file count when files are selected
    }
  }, [selectedClassifyFiles]);

  // Update the useEffect for node highlighting
  useEffect(() => {
    // Get all tags that would be extracted from selected nodes
    const extractableTags = new Set();
    const excludedNodeTags = new Set();
    const selectedNodePaths = new Set(selectedNodes.map((node) => node.id));

    // Helper to collect tags from a node
    const collectTags = (data) => {
      const traverse = (obj) => {
        if (!obj || typeof obj !== "object") return;
        Object.entries(obj).forEach(([key, value]) => {
          if (key !== "TagAvailableValues" && value?.TagAvailableValues) {
            if (excludedTags.has(key)) {
              excludedNodeTags.add(key);
            } else {
              extractableTags.add(key);
            }
          }
          if (typeof value === "object" && value !== null) {
            traverse(value);
          }
        });
      };
      traverse(data);
    };

    if (!selectedNodes.length) {
      // When no nodes selected, check all nodes for tags
      collectTags(discoveryGraphData);
    } else {
      // For selected nodes, only check their subtrees
      selectedNodes.forEach((node) => {
        const path = node.data.nodePath;
        let currentData = discoveryGraphData;

        // Navigate to the selected node
        for (let i = 0; i < path.length; i++) {
          if (currentData[path[i]]) {
            currentData = currentData[path[i]];
          }
        }

        collectTags(currentData);
      });
    }

    // Update node styling to show both extractable and excluded tags
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        const hasExtractableTag = node.data?.nodePath?.some((path) =>
          extractableTags.has(path),
        );
        const hasExcludedTag = node.data?.nodePath?.some((path) =>
          excludedNodeTags.has(path),
        );
        const isSelectedNode = selectedNodePaths.has(node.id);
        const isRootNode = node.type === "root";

        let nodeClass = (node.className || "")
          .replace(/bg-primary\/\d+|opacity-\d+|bg-gray-\d+/g, "")
          .trim();
        if (hasExcludedTag) {
          nodeClass = `${nodeClass}  opacity-40`;
        }
        // Only apply opacity if it's not the root node, not selected, and not extractable/excluded
        if (
          !isRootNode &&
          !hasExtractableTag &&
          !hasExcludedTag &&
          !isSelectedNode
        ) {
          nodeClass = `${nodeClass} opacity-40`;
        }

        return {
          ...node,
          className: nodeClass.trim(),
        };
      }),
    );
  }, [selectedNodes, discoveryGraphData, excludedTags, setNodes]);

  // Function to check if extraction is disabled
  const getDisabledReason = () => {
    // First check if there are any tags in the graph at all
    const hasAnyTags = Object.entries(discoveryGraphData).some(
      ([key, value]) =>
        key !== "TagAvailableValues" && value?.TagAvailableValues,
    );

    if (!hasAnyTags) {
      return "No tags available in the graph to extract";
    }

    // If "All Files" is selected, only check for tags
    if (selectedClassifyFiles === null) {
      return null;
    }

    // For other cases, check if we have any files selected
    const hasFilesSelected =
      (Array.isArray(selectedClassifyFiles) &&
        selectedClassifyFiles.length > 0) || // Specific files selected
      selectedNodesFileCount > 0; // Files from node selection

    if (!hasFilesSelected) {
      return "Select nodes or files to extract";
    }

    return null;
  };

  const handleNodeClick = (event, node) => {
    // Check if it's a root node
    if (node.data.nodeType === "root") {
      // Select all files
      setSelectedClassifyFiles(null); // null indicates all files are selected
      // Clear any selected nodes since we're selecting all files
      setSelectedNodes([]);
    } else {
      // Regular node selection logic
      setSelectedNodes((prev) => {
        const isSelected = prev.some((n) => n.id === node.id);
        // Clear file selection when selecting nodes
        if (!isSelected) {
          setSelectedClassifyFiles([]);
        }
        return isSelected
          ? prev.filter((n) => n.id !== node.id)
          : [...prev, node];
      });
    }
  };
  // Refresh stats when component mounts
  const initialRefresh = async () => {
    setIsRefreshing(true);
    try {
      await refreshHierarchyStats(deasyUserConfig.deasyApiKey);
    } catch (error) {
      console.error("Error refreshing initial stats:", error);
    } finally {
      setIsRefreshing(false);
    }
  };
  useEffect(() => {
    initialRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means it only runs on mount

  const handleTagExclusion = (tagName, e) => {
    e.stopPropagation();
    setExcludedTags((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(tagName)) {
        // When including a tag back, just remove it from excluded set
        newSet.delete(tagName);
      } else {
        // When excluding a tag, also exclude all its children
        newSet.add(tagName);
        const childTags = getChildTagsForTag(discoveryGraphData, tagName);
        childTags.forEach((tag) => newSet.add(tag));
      }
      return newSet;
    });
  };

  const handleReset = () => {
    // Reset all state
    setNodes([]);
    setEdges([]);
    setSelectedNodes([]);
    setSelectedNodeData(null);
    setSidePanelOpen(false);
    setSelectedClassifyFiles([]);
    setExcludedTags(new Set());
    setSearchTerm("");
    setTagSearchTerm("");
    setShowTagsList(false);
    setCollapsedTags(new Set());

    // Reset graph data to initial state and reprocess
    const { nodes: initialNodes, edges: initialEdges } = processData(
      discoveryGraphData,
      hierarchyStats?.file_count || 0,
    );
    setNodes(initialNodes);
    setEdges(initialEdges);
  };

  return (
    <div className="flex flex-col h-full">
      {/* Loading overlay */}
      {isConfigRefreshing && (
        <div className="absolute inset-0 bg-white/80 z-50 flex items-center justify-center">
          <div className="flex flex-col items-center gap-3">
            <CircularProgress size={24} className="text-primary" />
            <span className="text-sm text-gray-600">
              Updating configuration...
            </span>
          </div>
        </div>
      )}

      <div className="flex flex-col h-full">
        <div className="flex flex-col mb-4">
          <div className="flex items-end justify-between mb-2">
            <div className="flex-1 max-w-md">
              <h3 className="text-sm font-medium text-gray-700 mb-2 text-left">
                Select or Create Metadata Graph
              </h3>
              <GraphSelector handleRefresh={initialRefresh} />
            </div>
            <div className="ml-4 flex items-center">
              <input
                type="text"
                placeholder="Search nodes..."
                className="px-3 py-1.5 border border-gray-300 rounded-md min-w-80"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-2 ml-4">
              <button
                onClick={handleReset}
                className="flex items-center gap-2 px-3 py-1.5 text-sm rounded-md shadow-sm
                         transition-all duration-200 border bg-white text-gray-600 
                         hover:text-primary border-gray-200 hover:border-primary hover:shadow"
                title="Reset graph"
              >
                <RotateCcw className="w-4 h-4" />
                <span>Reset</span>
              </button>
              <button
                onClick={() => setShowTagNodes(!showTagNodes)}
                className={`px-3 py-1.5 text-sm rounded-md transition-all duration-200 flex items-center gap-2
                  ${showTagNodes ? "bg-primary/5 text-primary border border-primary/30" : "bg-gray-100 text-gray-500 border border-gray-200"}`}
                title={showTagNodes ? "Hide tag nodes" : "Show tag nodes"}
              >
                <Zap size={14} />
                <span>Tags</span>
              </button>
              <button
                onClick={() => setShowValueNodes(!showValueNodes)}
                className={`px-3 py-1.5 text-sm rounded-md transition-all duration-200 flex items-center gap-2
                  ${
                    showValueNodes
                      ? "bg-primary/5 text-primary border border-primary/30"
                      : "bg-gray-100 text-gray-500 border border-gray-200"
                  }`}
                title={showValueNodes ? "Hide value nodes" : "Show value nodes"}
              >
                <BetweenVerticalStart size={14} />
                <span>Values</span>
              </button>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={async () => {
                  setIsRefreshing(true);
                  await refreshHierarchyStats(deasyUserConfig.deasyApiKey);
                  setIsRefreshing(false);
                }}
                className={`flex items-center gap-2 px-3 py-1.5 text-sm rounded-md shadow-sm
                         transition-all duration-200 border
                         ${
                           isRefreshing
                             ? "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
                             : "bg-white text-gray-600 hover:text-primary border-gray-200 hover:border-primary hover:shadow"
                         }`}
                title="Refresh metadata stats"
                disabled={isRefreshing}
              >
                <FiRefreshCw className="w-4 h-4" />
                {isRefreshing ? (
                  <div className="flex items-center gap-2">
                    <span>Refreshing Stats</span>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-400" />
                  </div>
                ) : (
                  <span>Refresh Stats</span>
                )}
              </button>
              <button
                onClick={async () => {
                  setIsSaving(true);
                  await handleSaveGraph();
                  setIsSaving(false);
                }}
                className={`flex items-center gap-2 px-3 py-1.5 text-sm rounded-md shadow-sm
                         transition-all duration-200 border
                         ${
                           isSaving
                             ? "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
                             : "bg-white text-gray-600 hover:text-primary border-gray-200 hover:border-primary hover:shadow"
                         }`}
                title="Save Graph"
                disabled={isSaving}
              >
                <FiSave className="w-4 h-4" />
                {isSaving ? (
                  <div className="flex items-center gap-2">
                    <span>Saving Graph</span>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-400" />
                  </div>
                ) : (
                  <span>Save Graph</span>
                )}
              </button>
            </div>
          </div>
        </div>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodesWithCallbacks}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            fitView
            onNodeDrag={handleNodeDrag}
            onNodeDragStop={resetDragNode}
            onDragOver={onDragOver}
            onDrop={onDrop}
            minZoom={0.1}
            maxZoom={4}
            defaultEdgeOptions={{
              type: "step",
              style: { stroke: "#d1d5db" },
            }}
            onNodeClick={handleNodeClick}
          >
            <Background />
          </ReactFlow>

          {sidePanelOpen && (
            <AddNodeSidePanel handleAddNodeApplied={handleAddNodeApplied} />
          )}

          <div
            className={`
            fixed bottom-3 right-3 z-10 
            flex flex-col gap-2 p-4 
            rounded-lg border border-gray-200/20
            bg-gray-900/90 backdrop-blur-sm
            transition-all duration-300 
            ${showTagPanel ? "w-80" : "w-40"}
          `}
          >
            {/* Panel Header */}
            <div className="flex items-center justify-between text-white/90 mb-1">
              <h3 className="text-sm font-medium">Extract Metadata</h3>
              <button
                onClick={() => setShowTagPanel(!showTagPanel)}
                className="p-1 hover:bg-white/10 rounded-md transition-colors"
              >
                {showTagPanel ? <FiChevronRight /> : <FiChevronLeft />}
              </button>
            </div>

            {showTagPanel && (
              <div className="flex flex-col gap-2">
                {/* Extract Button */}
                {(() => {
                  const disabledReason = getDisabledReason();
                  const numberOfFilesToClassify =
                    selectedClassifyFiles === null // All Files selected
                      ? vdbFilesCount?.total_files || 0
                      : Array.isArray(selectedClassifyFiles) &&
                          selectedClassifyFiles.length > 0
                        ? selectedClassifyFiles.length
                        : selectedNodesFileCount;

                  const availableTags = getTagsFromSelectedNodes(
                    selectedNodes,
                    discoveryGraphData,
                  );

                  // Only disable if classifying, if there are no tags, or if there are no available tags
                  const isDisabled =
                    isClassifying ||
                    !!disabledReason ||
                    availableTags.size === 0;

                  return (
                    <div className="space-y-2">
                      <button
                        onClick={classify}
                        disabled={isDisabled}
                        title={
                          availableTags.size === 0
                            ? "No tags available to extract"
                            : disabledReason || ""
                        }
                        className={`
                          w-full px-3 py-2 rounded-md
                          text-sm font-medium
                          flex items-center gap-2
                          transition-all duration-200
                          ${
                            isDisabled
                              ? "bg-gray-600/20 text-white/30 cursor-not-allowed border border-white/5"
                              : "bg-primary hover:bg-primary/90 text-white shadow-lg hover:shadow-primary/20"
                          }
                        `}
                      >
                        <DatabaseZap
                          size={16}
                          className={isDisabled ? "opacity-30" : ""}
                        />
                        <span>
                          {isClassifying ? "Extracting..." : "Extract"}
                          {isDisabled &&
                            (availableTags.size === 0 ? (
                              <span className="ml-1 text-xs opacity-75">
                                (No tags available to extract)
                              </span>
                            ) : (
                              disabledReason && (
                                <span className="ml-1 text-xs opacity-75">
                                  ({disabledReason})
                                </span>
                              )
                            ))}
                        </span>
                        {numberOfFilesToClassify > 0 && !isDisabled && (
                          <span className="bg-white/20 px-2 py-0.5 rounded-full text-xs ml-auto">
                            {numberOfFilesToClassify.toLocaleString()}
                          </span>
                        )}
                      </button>

                      {/* Progress Bar */}
                      {isClassifying && (
                        <div className="space-y-1.5">
                          <div className="w-full bg-white/10 rounded-full h-1.5 overflow-hidden">
                            <div
                              className="bg-primary h-full transition-all duration-300 ease-in-out"
                              style={{ width: `${classifyProgress * 100}%` }}
                            />
                          </div>
                          <div className="flex items-center justify-between text-xs text-white/70">
                            <span>
                              {Math.round(classifyProgress * 100)}% Complete
                            </span>
                            {classifyTagsCreated > 0 && (
                              <span>{classifyTagsCreated} Tags Created</span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })()}

                {/* File Selection - only show when no nodes selected */}
                {selectedNodes.length === 0 && (
                  <button
                    onClick={() => setFilePickerOpen(true)}
                    className="w-full px-3 py-2 rounded-md text-sm
                      bg-white/10 text-white hover:bg-white/20
                      flex items-center justify-between transition-all duration-200"
                  >
                    <span>
                      {selectedClassifyFiles === null
                        ? "All Files"
                        : Array.isArray(selectedClassifyFiles) &&
                            selectedClassifyFiles.length > 0
                          ? `${selectedClassifyFiles.length} Files`
                          : "Select Files"}
                    </span>
                    {/* Show reset button if any files are selected */}
                    {(selectedClassifyFiles === null ||
                      (Array.isArray(selectedClassifyFiles) &&
                        selectedClassifyFiles.length > 0)) && (
                      <RotateCcw
                        size={14}
                        className="text-white/70 hover:text-white"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedClassifyFiles([]); // Reset to empty array
                          setSelectedNodes([]); // Clear selected nodes
                        }}
                      />
                    )}
                  </button>
                )}

                {/* Create Data Slice Button */}
                <button
                  onClick={() =>
                    !isLoadingFileCount && setShowCreateDataSlice(true)
                  }
                  disabled={
                    isLoadingFileCount ||
                    (selectedNodesFileCount === 0 &&
                      selectedClassifyFiles !== null) // Enable if All Files selected
                  }
                  className={`
                    w-full px-3 py-2 rounded-md
                    text-sm flex items-center gap-2
                    transition-all duration-200
                    ${
                      isLoadingFileCount ||
                      (selectedNodesFileCount === 0 &&
                        selectedClassifyFiles !== null)
                        ? "bg-white/10 text-white/50 cursor-not-allowed"
                        : "bg-white/10 text-white hover:bg-white/20"
                    }
                  `}
                >
                  <BetweenVerticalStart size={16} />
                  <span>Create Data Slice</span>
                  {(selectedNodesFileCount > 0 ||
                    selectedClassifyFiles === null) && (
                    <span className="bg-white/20 px-2 py-0.5 rounded-full text-xs ml-auto">
                      {selectedClassifyFiles === null
                        ? vdbFilesCount?.total_files?.toLocaleString() || "0"
                        : selectedNodesFileCount.toLocaleString()}
                    </span>
                  )}
                </button>

                {/* Tags Section */}
                <div className="space-y-1">
                  <button
                    onClick={() => setShowTagsList(!showTagsList)}
                    className="w-full px-3 py-2 rounded-md text-sm
                      bg-white/10 text-white hover:bg-white/20
                      flex items-center justify-between
                      transition-all duration-200"
                  >
                    <div className="flex items-center gap-2">
                      <FiChevronDown
                        className={`transition-transform ${showTagsList ? "rotate-180" : ""}`}
                        size={14}
                      />
                      <span>Tags</span>
                    </div>
                    <span className="bg-white/20 px-2 py-0.5 rounded-full text-xs">
                      {(() => {
                        const availableTags = getTagsFromSelectedNodes(
                          selectedNodes,
                          discoveryGraphData,
                        );
                        return availableTags.size;
                      })()}
                    </span>
                  </button>

                  {showTagsList && (
                    <div className="mt-1 bg-white/5 rounded-md p-2 space-y-1">
                      <div className="flex items-center justify-between px-4 py-3 mb-2 border-b border-white/10">
                        <span className="text-sm text-white font-medium">
                          All Tags
                        </span>
                        <button
                          onClick={() => {
                            const allTags =
                              getAllTagsFromGraph(discoveryGraphData);
                            setExcludedTags((prev) => {
                              const newSet = new Set(prev);
                              // If all tags are currently excluded, include all
                              if (
                                Array.from(allTags).every((tag) =>
                                  newSet.has(tag),
                                )
                              ) {
                                allTags.forEach((tag) => newSet.delete(tag));
                              } else {
                                // Otherwise, exclude all tags
                                allTags.forEach((tag) => newSet.add(tag));
                              }
                              return newSet;
                            });
                          }}
                          className="p-1 hover:bg-gray-100 rounded-full text-gray-400 hover:text-primary transition-colors"
                          title="Toggle all tags"
                        >
                          {Array.from(
                            getAllTagsFromGraph(discoveryGraphData),
                          ).every((tag) => excludedTags.has(tag)) ? (
                            <FiPlus size={16} />
                          ) : (
                            <FiMinus size={16} />
                          )}
                        </button>
                      </div>
                      <input
                        type="text"
                        placeholder="Search tags..."
                        value={tagSearchTerm}
                        onChange={(e) => setTagSearchTerm(e.target.value)}
                        className="w-full bg-white/10 text-white border-0 rounded-md px-3 py-1.5 text-sm
                          placeholder-white/50 focus:ring-1 focus:ring-white/30"
                      />
                      <div className="max-h-[30vh] overflow-y-auto space-y-0.5">
                        {(() => {
                          const getAllChildTags = (data) => {
                            const tags = new Map();

                            const traverse = (obj) => {
                              if (!obj || typeof obj !== "object") return;

                              Object.entries(obj).forEach(([key, value]) => {
                                if (
                                  key !== "TagAvailableValues" &&
                                  value?.TagAvailableValues
                                ) {
                                  tags.set(key, value);
                                }
                                if (
                                  typeof value === "object" &&
                                  value !== null
                                ) {
                                  traverse(value);
                                }
                              });
                            };

                            traverse(data);
                            return Array.from(tags.entries()).map(
                              ([tagName, tagData]) => ({
                                tagName,
                                tagData,
                              }),
                            );
                          };

                          // If no nodes selected, get all tags from the entire graph
                          const tags = !selectedNodes.length
                            ? getAllChildTags(discoveryGraphData)
                            : (() => {
                                // For selected nodes, get tags from their children
                                const relevantTags = new Map();
                                selectedNodes.forEach((node) => {
                                  const path = node.data.nodePath;
                                  let currentData = discoveryGraphData;

                                  // Navigate to the selected node
                                  for (let i = 0; i < path.length; i++) {
                                    if (currentData[path[i]]) {
                                      currentData = currentData[path[i]];
                                    }
                                  }

                                  // Get all child tags from this point
                                  getAllChildTags(currentData).forEach(
                                    ({ tagName, tagData }) => {
                                      relevantTags.set(tagName, tagData);
                                    },
                                  );
                                });

                                return Array.from(relevantTags.entries()).map(
                                  ([tagName, tagData]) => ({
                                    tagName,
                                    tagData,
                                  }),
                                );
                              })();

                          // Filter tags based on search term
                          const filteredTags = tags.filter((tag) =>
                            tag.tagName
                              .toLowerCase()
                              .includes(tagSearchTerm.toLowerCase()),
                          );

                          return filteredTags.map((tag) => (
                            <div key={tag.tagName} className="flex flex-col">
                              <div
                                className={`
                                  flex items-center justify-between
                                  px-4 py-3
                                  hover:bg-gray-50/10 transition-all duration-200
                                  cursor-pointer
                                  ${excludedTags.has(tag.tagName) ? "opacity-40" : ""}
                                `}
                                onClick={() => {
                                  setCollapsedTags((prev) => {
                                    const newSet = new Set(prev);
                                    if (newSet.has(tag.tagName)) {
                                      newSet.delete(tag.tagName);
                                    } else {
                                      newSet.add(tag.tagName);
                                    }
                                    return newSet;
                                  });
                                }}
                              >
                                <div className="flex items-center gap-2">
                                  <FiChevronDown
                                    size={16}
                                    className={`transition-transform duration-200 text-gray-500
                                      ${collapsedTags.has(tag.tagName) ? "" : "rotate-180"}`}
                                  />
                                  <span className="text-sm text-white">
                                    {tag.tagName}
                                  </span>
                                </div>

                                <button
                                  onClick={(e) =>
                                    handleTagExclusion(tag.tagName, e)
                                  }
                                  className={`p-1 hover:bg-gray-100 rounded-full ${
                                    excludedTags.has(tag.tagName)
                                      ? "text-red-500 bg-red-50"
                                      : "text-gray-400 hover:text-red-500"
                                  }`}
                                  title={
                                    excludedTags.has(tag.tagName)
                                      ? "Include tag"
                                      : "Exclude tag"
                                  }
                                >
                                  {excludedTags.has(tag.tagName) ? (
                                    <FiPlus size={16} />
                                  ) : (
                                    <FiMinus size={16} />
                                  )}
                                </button>
                              </div>

                              {!collapsedTags.has(tag.tagName) &&
                                tag.tagData.TagAvailableValues && (
                                  <div className="bg-gray-50 px-4 py-2 space-y-1">
                                    {tag.tagData.TagAvailableValues.map(
                                      (value) => (
                                        <div
                                          key={value}
                                          className="text-sm text-gray-600 px-3 py-1.5 rounded bg-white flex items-center justify-between"
                                        >
                                          <span>{value}</span>
                                        </div>
                                      ),
                                    )}
                                  </div>
                                )}
                            </div>
                          ));
                        })()}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </ReactFlowProvider>

        {showCreateDataSlice && (
          <CreateDataSlice
            isOpen={showCreateDataSlice}
            onClose={() => setShowCreateDataSlice(false)}
            conditions={
              // If all files selected (selectedClassifyFiles === null), pass empty array for conditions
              selectedClassifyFiles === null
                ? []
                : selectedNodes
                    .map((node) => {
                      const path = node.data.nodePath;
                      const tagIndex = path.length - 2;
                      const valueIndex = path.length - 1;
                      return {
                        tag_id: path[tagIndex],
                        values: [path[valueIndex]],
                      };
                    })
                    .flat()
            }
            fileCount={
              selectedClassifyFiles === null
                ? vdbFilesCount?.total_files || 0
                : selectedNodesFileCount
            }
            discoveryGraphData={discoveryGraphData}
            selectedDiscoveryGraph={selectedDiscoveryGraph}
            onSuccess={(useCase) => {
              setSelectedUseCase(useCase);
              setCurrentScreen(WorkflowType.DATA_SLICE_MANAGEMENT);
            }}
            isGraphMode={true}
            isAllFiles={selectedClassifyFiles === null}
          />
        )}
        <FilePickerModal
          openFilePicker={filePickerOpen}
          setOpenFilePicker={setFilePickerOpen}
          selectedFiles={
            Array.isArray(selectedClassifyFiles) ? selectedClassifyFiles : []
          }
          setSelectedFiles={(files) => {
            setSelectedClassifyFiles(files);
          }}
          selectAllForEntireDB={true}
        />
      </div>
    </div>
  );
};

export { ExtractMetadataTab };
