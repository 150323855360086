import { useContext, useState, useEffect } from "react";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { FaChevronLeft, FaChevronDown, FaChevronUp } from "react-icons/fa";
import GeneralSection from "./GeneralSection";
import ExamplesSection from "./ExamplesSection";
import { Tag } from "lucide-react";

const TagEditor = ({
  collapseDirection = "horizontal",
  disableCollapse = false,
  showHeader = true,
  tagInEditor,
  setTagInEditor,
  isTagEditorCollapsed,
  setIsTagEditorCollapsed,
  setIsTagSelectorExpanded = null,
  activeTagEditorSection = "general",
  setActiveTagEditorSection,
}) => {
  const { handleSaveMetadata } = useContext(BaseContext);
  const isExpanded = !isTagEditorCollapsed || disableCollapse;

  const [saving, setSaving] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    output_type: "word",
    available_values: [],
    tuned: false,
  });

  useEffect(() => {
    if (tagInEditor && Object.keys(tagInEditor).length > 0) {
      const { isNewlySaved, ...cleanTag } = tagInEditor;

      setFormData({
        ...cleanTag,
        output_type: cleanTag.output_type || "word",
        available_values: cleanTag.available_values || [],
        tuned: typeof cleanTag.tuned === "number" ? cleanTag.tuned : 0,
      });
    } else {
      setFormData({
        name: "",
        description: "",
        output_type: "word",
        available_values: [],
        tuned: 0,
      });
    }
  }, [tagInEditor]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "available_values") {
      const valueArray = value === "" ? [] : value.split("\n");

      setFormData((prev) => ({
        ...prev,
        [name]: valueArray,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    setSaving(true);
    await handleSaveMetadata(formData);
    setSaving(false);
  };

  return (
    <div>
      {showHeader && (
        <TagEditorHeader
          collapseDirection={collapseDirection}
          disableCollapse={disableCollapse}
          isTagEditorCollapsed={isTagEditorCollapsed}
          setIsTagEditorCollapsed={setIsTagEditorCollapsed}
          setIsTagSelectorExpanded={setIsTagSelectorExpanded}
          tagInEditor={tagInEditor}
        />
      )}

      <div
        className={`${showHeader ? "h-[calc(100%-64px)]" : "h-full"} flex flex-col`}
      >
        <div className="flex-1 overflow-y-auto">
          <div className="flex bg-white border-b border-gray-200 w-full">
            <button
              className={`flex-1 py-3 px-6 text-sm font-medium text-center transition-all
                ${
                  activeTagEditorSection === "general"
                    ? "border-b-2 border-primary text-emerald-600 bg-emerald-50"
                    : "border-transparent text-gray-600 hover:bg-gray-50"
                }`}
              onClick={() => setActiveTagEditorSection("general")}
            >
              General Settings
            </button>
            <button
              className={`flex-1 py-3 px-6 text-sm font-medium text-center transition-all
                ${
                  activeTagEditorSection === "examples"
                    ? "border-b-2 border-primary text-emerald-600 bg-emerald-50"
                    : "border-transparent text-gray-600 hover:bg-gray-50"
                }`}
              onClick={() => setActiveTagEditorSection("examples")}
            >
              Training Examples
            </button>
          </div>

          {isExpanded && (
            <div className="p-6 space-y-6">
              {!tagInEditor ? (
                <div className="text-center py-12">
                  <div className="text-gray-400 text-lg">No Tag Selected</div>
                  <p className="text-gray-500 mt-2">
                    Select a tag from the list to edit its properties
                  </p>
                </div>
              ) : activeTagEditorSection === "general" ? (
                <GeneralSection
                  formData={formData}
                  setFormData={setFormData}
                  handleInputChange={handleInputChange}
                  setTagInEditor={setTagInEditor}
                />
              ) : activeTagEditorSection === "examples" ? (
                <ExamplesSection
                  tagInEditor={tagInEditor}
                  setTagInEditor={setTagInEditor}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </div>

        <div className="p-6 flex flex-col gap-3 border-t border-gray-200 bg-gray-50">
          <button
            onClick={() => !saving && handleSave()}
            className={`w-full py-2.5 px-4 rounded-lg bg-primary text-white transition-all
              ${
                !formData.name ||
                !formData.description ||
                !formData.output_type ||
                saving
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-primary/90"
              }`}
            disabled={
              !formData.name ||
              !formData.description ||
              !formData.output_type ||
              saving
            }
          >
            {saving ? (
              <div className="flex items-center justify-center gap-2">
                <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin"></div>
                <span>Saving...</span>
              </div>
            ) : (
              "Save Tag"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const TagEditorHeader = ({
  collapseDirection,
  labelStyles,
  labelRotation,
  headerClasses,
  disableCollapse,
  isTagEditorCollapsed,
  setIsTagEditorCollapsed,
  setIsTagSelectorExpanded,
  tagInEditor,
}) => {
  return (
    <>
      <div
        className={headerClasses}
        onClick={() =>
          !disableCollapse && setIsTagEditorCollapsed(!isTagEditorCollapsed)
        }
      >
        {(!isTagEditorCollapsed || collapseDirection === "vertical") && (
          <div className="flex w-full items-center justify-between p-3">
            <div className="flex items-center gap-2">
              <Tag size={24} className="text-primary" />
              <h2 className="text-xl font-semibold text-left">
                Metadata Editor
              </h2>
            </div>
            {!(setIsTagSelectorExpanded === null) && (
              <button
                onClick={() => setIsTagSelectorExpanded(true)}
                className={`p-2 rounded-full shadow-md hover:opacity-80 transition-colors duration-200 truncate px-4 ${
                  tagInEditor?.name
                    ? "w-1/2 bg-white text-gray-500 "
                    : "w-1/2 bg-emerald-50 border-primary text-emerald-600 border-2 font-medium"
                }`}
                title="Collapse"
              >
                {tagInEditor?.name ? tagInEditor.name : "Select a Tag"}
              </button>
            )}
          </div>
        )}
        {!disableCollapse && (
          <button className="text-gray-500 hover:text-gray-700">
            {collapseDirection === "vertical" ? (
              isTagEditorCollapsed ? (
                <FaChevronDown />
              ) : (
                <FaChevronUp />
              )
            ) : isTagEditorCollapsed ? (
              <FaChevronLeft />
            ) : (
              <></>
            )}
          </button>
        )}
      </div>
      {isTagEditorCollapsed && collapseDirection === "horizontal" && (
        <div className="flex justify-center items-center p-6 ">
          <h2
            className={`whitespace-nowrap font-semibold ${labelRotation}`}
            style={labelStyles}
          >
            Metadata Editor
          </h2>
        </div>
      )}
    </>
  );
};

export default TagEditor;
